import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../containers/SaasModern/sassModern.style';
import BannerSection from '../containers/SaasModern/Banner';
import Navbar from '../containers/SaasModern/Navbar';
import WorkingProcessSection from '../containers/SaasModern/WorkingProcess';
import FaqSection from '../containers/SaasModern/Faq';
import InfoSection from '../containers/SaasModern/Info';
import Footer from '../containers/SaasModern/Footer';
import SEO from '../components/seo';
import LeadSection from '../containers/Saas/LeadSection';
import PricingSection from '../containers/SaasModern/Pricing';
import TrialSection from '../containers/SaasModern/Trial';
import TestimonialSection from '../containers/SaasModern/Testimonial';
import Typist from 'react-typist';
import PageWrapper from '../components/PageWrapper';
import { Modal } from '@redq/reuse-modal';

export default () => {
  return (
    <PageWrapper>
      <ThemeProvider theme={saasModernTheme}>
        <Fragment>
          <SEO title="B2B SaaS Leads"/>

          <ResetCSS/>
          <GlobalStyle/>

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar/>
              </DrawerProvider>
            </Sticky>
            <BannerSection
              heading={<>
                Find contacts at every company that uses
                <Typer/>
              </>}
              descriptionText={<>
                Get emails of key decision makers at every company that uses a technology and
                start
                selling 🚀 <br/>
                Here's what you get 👇
              </>}
              airtableUrl="https://airtable.com/embed/shrpijbPZw90bU1r2?"/>
            <WorkingProcessSection/>
            <InfoSection/>
            <LeadSection/>
            {/*<FeatureSection />*/}
            {/*<UpdateScreen />*/}
            <PricingSection/>
            {/* <PartnerSection />*/}
            <TestimonialSection/>
            <FaqSection/>
            <TrialSection/>
            <Footer/>
          </ContentWrapper>
          <Modal/>
        </Fragment>
      </ThemeProvider>
    </PageWrapper>
  );
};

function Typer() {
  const [count, setCount] = React.useState(1);
  React.useEffect(() => {
    setCount(1);
  }, [count]);
  const techs = [
    'Shopify',
    'Stripe',
    'Google Maps',
    'Cloudflare',
    'Wordpress',
    'Google Adsense',
    'React',
    'Mailchimp',
    'Zendesk'
  ];
  const delay = 1000;
  return (
    <Typist
      className="inline-block"
      key={count}
      onTypingDone={() => setCount(0)}
      cursor={{
        blink: true,
        hideWhenDone: true
      }}
    >
      <span>{techs[0]}</span>
      <Typist.Backspace count={techs[0].length} delay={delay}/>
      <span>{techs[1]}</span>
      <Typist.Backspace count={techs[1].length} delay={delay}/>
      <span>{techs[2]}</span>
      <Typist.Backspace count={techs[2].length} delay={delay}/>
      <span>{techs[3]}</span>
      <Typist.Backspace count={techs[3].length} delay={delay}/>
      <span>{techs[4]}</span>
      <Typist.Backspace count={techs[4].length} delay={delay}/>
      <span>{techs[5]}</span>
      <Typist.Backspace count={techs[5].length} delay={delay}/>
      <span>{techs[6]}</span>
      <Typist.Backspace count={techs[6].length} delay={delay}/>
      <span>{techs[7]}</span>
      <Typist.Backspace count={techs[7].length} delay={delay}/>
      <span>{techs[8]}</span>
      <Typist.Backspace count={techs[8].length} delay={delay}/>
    </Typist>
  );
}